@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Poppins:wght@400;700&family=Roboto:wght@400;700&family=Merriweather:wght@400;700&family=Fira+Code:wght@400;700&family=Public+Sans:wght@400;700&family=Lato:wght@400;700&family=Montserrat:wght@400;700&family=Ubuntu:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.font-segoe {
    --font-family: 'Segoe UI', Arial, sans-serif;
    font-family: var(--font-family);
}
.font-inter {
    --font-family: 'Inter', sans-serif;
    font-family: var(--font-family);
}
.font-poppins {
    --font-family: 'Poppins', sans-serif;
    font-family: var(--font-family);
}
.font-roboto {
    --font-family: 'Roboto', sans-serif;
    font-family: var(--font-family);
}
.font-publicSans {
    --font-family: 'Public Sans', sans-serif;
    font-family: var(--font-family);
}
.font-lato {
    --font-family: 'Lato', sans-serif;
    font-family: var(--font-family);
}
.font-montserrat {
    --font-family: 'Montserrat', sans-serif;
    font-family: var(--font-family);
}
.font-ubuntu {
    --font-family: 'Ubuntu', sans-serif;
    font-family: var(--font-family);
}
.font-serif {
    --font-family: 'Merriweather', serif;
    font-family: var(--font-family);
}
.font-mono {
    --font-family: 'Fira Code', monospace;
    font-family: var(--font-family);
}
